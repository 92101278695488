import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About Blue Boat
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              Better Game Better Life{" "}
              <span role="img" aria-label="heart" className="text-red-600">
                ❤️
              </span>
            </h2>
            <div className="mt-4 leading-loose">
              <p className="mt-1">
                Blue Boat is a game studio with honest ambitions.
              </p>
              <p className="mt-1">
                Founded in 2012, we've been developing brain games, puzzle games, and educational games for players around the world on Facebook, Web, iOS, and Android platforms.
              </p>
              <p className="mt-1">
                We believe better games can help people become the better version of themselves.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.improver.childImageSharp.fluid}
              alt="Better Game Better Life"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;

export const query = graphql`
  query {
    improver: file(relativePath: { eq: "improver.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
